import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Header, Navbar } from "../components/Layout";
import Style from "./routes.module.scss";

class PrivateRouter extends React.Component {
  state = { isCollapsedMenu: false };
  toggleCollapsedMenu = () => {
    this.setState((prevState) => ({
      isCollapsedMenu: !prevState.isCollapsedMenu,
    }));
  };

  render() {
    const { userLogin = {} } = this.props;
    const { isCollapsedMenu } = this.state;
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(propsRoute) => {
          if (Object.keys(userLogin).length > 0) {
            return (
              <div className={Style.layout_wrapper}>
                <Header
                  toggleCollapsedMenu={this.toggleCollapsedMenu}
                  isCollapsedMenu={isCollapsedMenu}
                />
                <Navbar
                  toggleCollapsedMenu={this.toggleCollapsedMenu}
                  isCollapsedMenu={isCollapsedMenu}
                />
                <div
                  className={`${Style._main} ${
                    isCollapsedMenu && Style.isCollapsedContent
                  }`}
                >
                  <Component {...propsRoute} />
                </div>
              </div>
            );
          } else {
            return <Redirect to={"/login"} />;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userLogin: state.userLogin.userInfo,
  };
};

export default connect(mapStateToProps, null)(PrivateRouter);
