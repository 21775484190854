import React from "react";
import { connect } from "react-redux";
import { Admin } from "../../lib/services";
import { Table, Form, Button, Modal, Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Style from "./style.module.scss";
import "./Uploader.scss";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { CiSearch, CiEdit } from "react-icons/ci";
import Swal from "sweetalert2";
import {
  FormatCurrencyToVND,
  _updatePageTitleAndDescription,
} from "../../utils/systems";

const { CreateItem, deleteItem, updateItem, UploadImage } = Admin.PostRequests;
const { GetAllItems, GetAllCategories, GetAllGroupModifiers } =
  Admin.GetRequests;

class Item extends React.Component {
  state = {
    category_key: "",
    itemIsOnSale: false,
    itemSaleRate: 0,
    itemIsInStock: false,
    item_description: "",
    thumbnail_image: [],
    item_name: "",
    item_price: 0,
    modifier_group_keys: [0],
    showModal: false,
    selectedItems: [],
    selectAll: false,
    isEditMode: false,
    editingItemId: null,
    currentPage: 1,
    itemsPerPage: 4,
    isPublishedProduct: false,
    selectedStatus: 0,
    selectedStockStatus: 0,
    searchQuery: "",
    selectedCategory: "",
  };

  componentDidMount() {
    this.props.GetAllItems();
    this.props.GetAllCategories();
    this.props.GetAllGroupModifiers();
    _updatePageTitleAndDescription({
      description: "Quản lí món ăn",
      title: "Quản lí món ăn",
    });
  }
  handleSearchChange = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleEditItem = (itemId) => {
    const { allItems } = this.props;
    const item = allItems[itemId];
    this.setState({
      isEditMode: true,
      editingItemId: itemId,
      category_key: item.category_key,
      itemIsOnSale: item.itemIsOnSale,
      itemSaleRate: item.itemSaleRate,
      item_description: item.item_description,
      thumbnail_image: item.thumbnail_image,
      item_name: item.item_name,
      item_price: item.item_price,
      modifier_group_keys: item.modifier_group_keys,
      isPublishedProduct: item.isPublishedProduct,
      itemIsInStock: item.itemIsInStock,
      showModal: true,
    });
  };

  handleClickItem = () => {
    const {
      category_key,
      itemIsOnSale,
      itemSaleRate,
      item_description,
      thumbnail_image = [],
      item_name,
      item_price,
      itemIsInStock,
      isPublishedProduct,
      modifier_group_keys,
    } = this.state;

    const nameRegex = /[a-zA-Z]/;
    if (!nameRegex.test(item_name)) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Tên mặt hàng phải chứa ít nhất một ký tự chữ cái.",
      });
      return;
    }

    const itemInfo = {
      category_key,
      itemIsOnSale,
      itemSaleRate,
      item_description,
      thumbnail_image,
      item_name,
      item_price,
      itemIsInStock,
      isPublishedProduct,
      modifier_group_keys,
    };

    if (!category_key || !item_price) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Vui lòng điền đầy đủ thông tin bắt buộc.",
      });
      return;
    }
    if (thumbnail_image.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Phải có ít nhất 1 ảnh",
      });
      return;
    }

    this.props
      .CreateItem(itemInfo)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Thành công!",
          text: "Mặt hàng đã được tạo thành công.",
        });

        this.handleCloseModal();
        this.props.GetAllItems();
        this.setState({
          category_key: "",
          itemIsOnSale: false,
          itemSaleRate: 0,
          item_description: "",
          thumbnail_image: [],
          item_name: "",
          item_price: 0,
          modifier_group_keys: [0],
          showModal: false,
          showModalDelete: false,
          itemIsInStock: false,
          isPublishedProduct: false,
        });
      })
      .catch((error) => {
        console.error("Đã xảy ra lỗi khi tạo mặt hàng:", error);

        Swal.fire({
          icon: "error",
          title: "Lỗi!",
          text: "Đã xảy ra lỗi khi tạo mặt hàng. Vui lòng thử lại sau.",
        });
      });
  };

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let inputValue;
    if (type === "checkbox") {
      inputValue = checked;
    } else if (type === "number") {
      const numericValue = parseFloat(value);
      inputValue = !isNaN(numericValue) && numericValue >= 0 ? numericValue : 0;
    } else {
      inputValue = value;
    }

    this.setState({ [name]: inputValue });
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };
  handleDeleteItem = () => {
    const { selectedItems } = this.state;

    if (!selectedItems.length) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Vui lòng chọn ít nhất một mặt hàng để xóa.",
      });
      return;
    }

    this.props
      .deleteItem(selectedItems)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Thành công!",
          text: "Mặt hàng đã được xóa thành công.",
        });

        this.props.GetAllItems();

        this.setState({
          selectedItems: [],
        });
      })
      .catch((error) => {
        console.error("Đã xảy ra lỗi khi xóa mặt hàng:", error);

        Swal.fire({
          icon: "error",
          title: "Lỗi!",
          text: "Đã xảy ra lỗi khi xóa mặt hàng. Vui lòng thử lại sau.",
        });
      });
  };

  handleCategoryChange = (e) => {
    const { value } = e.target;
    this.setState({ category_key: value });
  };
  handleModifierGroupChange = (e) => {
    const { value } = e.target;
    this.setState({ modifier_group_key: value });
  };
  handleShowModalDelete = () => {
    this.setState({ showModalDelete: true });
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  handleImageUpload = async (files) => {
    const currentImagesCount = this.state.thumbnail_image.length;
    const remainingSlots = 5 - currentImagesCount;

    const filesToUpload = Array.from(files).slice(0, remainingSlots);

    if (filesToUpload.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Chú ý!",
        text: "Chỉ được tải lên tối đa 5 ảnh.",
      });
      return;
    }

    const uploadPromises = filesToUpload.map(async (file) => {
      if (file.size > 500 * 1024) {
        Swal.fire({
          icon: "error",
          title: "Lỗi!",
          text: "Hình ảnh phải nhỏ hơn 500kb.",
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const path = reader.result;

        try {
          Swal.fire({
            title: "Đang xử lý...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          await this.props.UploadImage(path);

          Swal.fire({
            icon: "success",
            title: "Thành công!",
            text: "Hình ảnh đã được tải lên thành công.",
          });
          this.setState({
            thumbnail_image: [...this.state.thumbnail_image, this.props.path],
          });
        } catch (error) {
          console.error("Đã xảy ra lỗi khi tải lên hình ảnh:", error);

          Swal.fire({
            icon: "error",
            title: "Lỗi!",
            text: "Đã xảy ra lỗi khi tải lên hình ảnh. Vui lòng thử lại sau.",
          });
        }
      };

      reader.readAsDataURL(file);
    });

    await Promise.all(uploadPromises);
  };

  addModifierGroup = () => {
    this.setState((prevState) => {
      const modifier_group_keys = prevState.modifier_group_keys || [];
      return {
        ...prevState,
        modifier_group_keys: [...modifier_group_keys, ""],
      };
    });
  };

  removeModifierGroup = (index) => {
    this.setState((prevState) => ({
      modifier_group_keys: prevState.modifier_group_keys.filter(
        (_, i) => i !== index
      ),
    }));
  };
  handleModifierGroupChangeAdditional = (index, event) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const modifier_group_keys = [...prevState.modifier_group_keys];
      modifier_group_keys[index] = value;
      return { modifier_group_keys };
    });
  };
  handleRemoveImage = (index) => {
    const updatedThumbnailImages = [...this.state.thumbnail_image];

    if (updatedThumbnailImages.length > 1) {
      updatedThumbnailImages.splice(index, 1);

      this.setState({
        thumbnail_image: updatedThumbnailImages,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Phải có ít nhất 1 ảnh trong item.",
      });
    }
  };

  handleUpdateItem = () => {
    const {
      category_key,
      itemIsOnSale,
      itemSaleRate,
      item_description,
      thumbnail_image,
      item_name,
      item_price,
      modifier_group_keys,
      editingItemId,
      itemIsInStock,
      isPublishedProduct,
    } = this.state;

    const updatedItemInfo = {
      category_key,
      itemIsOnSale,
      itemSaleRate,
      item_description,
      thumbnail_image,
      item_name,
      item_price,
      modifier_group_keys,
      itemIsInStock,
      isPublishedProduct,
    };
    if (!category_key || !item_name || !item_price) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Vui lòng điền đầy đủ thông tin bắt buộc.",
      });
      return;
    }

    this.props
      .updateItem(editingItemId, updatedItemInfo)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Thành công!",
          text: "Mặt hàng đã được cập nhật thành công.",
        });

        this.handleCloseModal();
        this.props.GetAllItems();
        this.setState({
          isEditMode: false,
          editingItemId: null,
          category_key: "",
          itemIsOnSale: false,
          itemSaleRate: 0,
          item_description: "",
          thumbnail_image: [],
          item_name: "",
          item_price: 0,
          modifier_group_keys: [0],
          showModal: false,
          isPublishedProduct: false,
          itemIsInStock: false,
        });
      })
      .catch((error) => {
        console.error("Đã xảy ra lỗi khi cập nhật mặt hàng:", error);

        Swal.fire({
          icon: "error",
          title: "Lỗi!",
          text: "Đã xảy ra lỗi khi cập nhật mặt hàng. Vui lòng thử lại sau.",
        });
      });
  };

  handleCloseModal = () => {
    this.setState({
      isEditMode: false,
      editingItemId: null,
      category_key: "",
      itemIsOnSale: false,
      itemSaleRate: 0,
      item_description: "",
      thumbnail_image: [],
      item_name: "",
      item_price: 0,
      modifier_group_keys: [0],
      showModal: false,
      itemIsInStock: false,
      isPublishedProduct: false,
    });
    this.setState({ showModal: false });
  };
  handleCheckboxChange = (itemId) => {
    this.setState((prevState) => {
      const selectedItems = prevState.selectedItems.includes(itemId)
        ? prevState.selectedItems.filter((id) => id !== itemId)
        : [...prevState.selectedItems, itemId];

      return { selectedItems };
    });
  };
  handleSelectAllChange = () => {
    this.setState((prevState) => {
      const { allItems = {} } = this.props;
      const allItemIds = Object.keys(allItems);

      const selectedItems = prevState.selectAll
        ? [] // If "Select All" was checked, uncheck all items
        : allItemIds; // If "Select All" was unchecked, check all items

      return { selectedItems, selectAll: !prevState.selectAll };
    });
  };
  handlePreviousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };
  handleNextPage = () => {
    const { currentPage, itemsPerPage } = this.state;
    const { allItems = {} } = this.props;
    const totalPages = Math.ceil(Object.keys(allItems).length / itemsPerPage);
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };
  handleItemsPerPageChange = (event) => {
    const itemsPerPage = parseInt(event.target.value, 10);
    this.setState({
      itemsPerPage,
      currentPage: 1, 
    });
  };
  getTotalItems = () => {
    const { allItems = {} } = this.props;
    return allItems ? Object.keys(allItems).length : 0;
  };

  getStartRange = () => {
    const { currentPage, itemsPerPage } = this.state;
    const totalItems = this.getTotalItems();
    return totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  };

  getEndRange = () => {
    const { currentPage, itemsPerPage } = this.state;
    const totalItems = this.getTotalItems();
    const calculatedEndRange = currentPage * itemsPerPage;
    return Math.min(calculatedEndRange, totalItems);
  };

  renderTableRows() {
    const {
      allItems = {},
      allCategories = {},
      allGroupModifiers = {},
    } = this.props;
    const { currentPage, itemsPerPage, selectedItems } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Object.keys(allItems)
      .reverse()
      .filter((key) => {
        const item = allItems[key];
        const statusFilter =
          this.state.selectedStatus === 0 ||
          (this.state.selectedStatus === 1 && item.isPublishedProduct) ||
          (this.state.selectedStatus === 2 && !item.isPublishedProduct);

        const stockFilter =
          this.state.selectedStockStatus === 0 ||
          (this.state.selectedStockStatus === 1 && item.itemIsInStock) ||
          (this.state.selectedStockStatus === 2 && !item.itemIsInStock);

        const nameFilter = item.item_name
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase());
        const categoryFilter =
          this.state.selectedCategory === "" ||
          this.state.selectedCategory ===
            (allCategories[item.category_key]?.name || "");

        return statusFilter && stockFilter && nameFilter && categoryFilter;
      })
      .slice(indexOfFirstItem, indexOfLastItem)
      .map((key) => {
        const item = allItems[key];
        const categoryName =
          allCategories[item.category_key]?.name || "Không có danh mục";
        const modifier_group_names = item.modifier_group_keys?.map(
          (key, index) => {
            const modifier_group = allGroupModifiers[key];
            const name = modifier_group
              ? modifier_group.modifier_group_name
              : "Không có";
            const truncatedName = name.length > 15 ? name.slice(0, 15) : name;
            const comma =
              index < item.modifier_group_keys.length - 1 ? ", " : "";
            return truncatedName + comma;
          }
        );

        return (
          <tr key={item._id} className={Style.item_row}>
            <td>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedItems.includes(item._id)}
                onChange={() => this.handleCheckboxChange(item._id)}
              />
            </td>
            <td>
              <img
                className={Style.thumbnail_item}
                src={item.thumbnail_image[0]}
                alt={item.item_name}
              />
            </td>
            <td>{item.item_name}</td>
            <td>{FormatCurrencyToVND(item.item_price)}</td>
            <td>{item.itemSaleRate}%</td>
            <td>{categoryName}</td>
            <td>{modifier_group_names}</td>
            <td>{item.isPublishedProduct ? "Hiện sản phẩm" : "Ẩn sản phẩm"}</td>
            <td>{item.itemIsInStock ? "Còn hàng" : "Hết hàng"}</td>
            <td>
              <div className={Style.btn_action_group}>
                <button
                  type="button"
                  className={`${Style.item_action} btn`}
                  onClick={() => this.handleEditItem(item._id)}
                >
                  <CiEdit />
                </button>
              </div>
            </td>
          </tr>
        );
      });
    return currentItems;
  }

  render() {
    const startRange = this.getStartRange();
    const endRange = this.getEndRange();
    const {
      allCategories = {},
      allItems = {},
      allGroupModifiers = {},
    } = this.props;

    const { itemIsOnSale, selectAll, currentPage, itemsPerPage } = this.state;
    const pageNumbers = [];
    for (
      let number = 1;
      number <= Math.ceil(Object.keys(allItems).length / itemsPerPage);
      number++
    ) {
      pageNumbers.push(number);
    }

    return (
      <div className={Style.table_container}>
        <h2>Quản lí sản phẩm</h2>
        <div className={Style.header_table}>
          <div className={Style.header_top_bar}>
            <h4 className="mb-4">Lọc sản phẩm</h4>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    this.setState({ selectedStatus: parseInt(e.target.value) })
                  }
                >
                  <option value={0} selected>
                    Trạng thái
                  </option>
                  <option value={1}>Hiện sản phẩm</option>
                  <option value={2}>Ẩn sản phẩm</option>
                </select>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    this.setState({
                      selectedCategory: e.target.value,
                      currentPage: 1,
                    })
                  }
                >
                  <option value="" selected>
                    Danh mục
                  </option>
                  {Object.values(allCategories)?.map((category) => (
                    <option value={category.name}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    this.setState({
                      selectedStockStatus: parseInt(e.target.value),
                    })
                  }
                >
                  <option value={0} selected>
                    Tình trạng hàng hoá
                  </option>
                  <option value={1}>Còn hàng</option>
                  <option value={2}>Hết hàng</option>
                </select>
              </div>
            </div>
          </div>
          <div className={Style.header_table_container}>
            <div className={`${Style.app_search} d-none d-lg-block mx-3`}>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tìm kiếm theo tên sản phẩm..."
                  value={this.state.searchQuery}
                  onChange={(e) => this.handleSearchChange(e.target.value)}
                />

                <CiSearch />
              </div>
            </div>
            <div>
              <button
                className={Style.header_add_button}
                onClick={this.handleShowModal}
              >
                <div>
                  <FaPlus />
                  <span>Thêm sản phẩm</span>
                </div>
              </button>
              <button
                className={Style.header_delete_button}
                onClick={this.handleDeleteItem}
              >
                <div>
                  <FaRegTrashAlt />
                  <span>Xoá</span>
                </div>
              </button>
            </div>
          </div>
          <Table className={Style.table} responsive="lg" hover>
            <thead>
              <tr>
                <th>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={selectAll}
                    onChange={this.handleSelectAllChange}
                  />
                </th>
                <th>Hình Ảnh</th>
                <th>Tên Sản Phẩm</th>
                <th>Giá</th>
                <th>Tỉ Lệ Giảm Giá</th>
                <th>Thuộc Danh Mục</th>
                <th>Tên mục ăn kèm</th>
                <th>Trạng thái</th>
                <th>Tồn kho</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.renderTableRows()}</tbody>
          </Table>

          <Modal
            className={Style.modal}
            fullscreen
            show={this.state.showModal}
            onHide={this.handleCloseModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.isEditMode ? "Sửa sản phẩm" : "Thêm sản phẩm"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="row">
                <div className="col-12 col-lg-8">
                  <p className={Style.add_product_title}>Thông tin sản phẩm</p>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingInputValue"
                      defaultValue={""}
                      name="item_name"
                      value={this.state.item_name}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="floatingInputValue">Tên Sản Phẩm</label>
                  </div>
                  <div className="form-floating my-3">
                    <textarea
                      className="form-control"
                      id="floatingTextarea"
                      defaultValue={""}
                      name="item_description"

                      onChange={this.handleInputChange}

                      value={this.state.item_description}

                    />
                    <label htmlFor="floatingTextarea">Mô tả sản phẩm</label>
                  </div>
                  <div id="file-upload-form" className="uploader">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Hình ảnh</span>
                      <small className="fw-light">Add media from URL</small>
                    </div>
                    <input
                      onChange={(e) => this.handleImageUpload(e.target.files)}
                      id="file-upload"
                      type="file"
                      name="fileUpload"
                      accept="image/*"
                    />

                    <label htmlFor="file-upload" id="file-drag">
                      <img
                        id="file-image"
                        src="#"
                        alt="Preview"
                        className="hidden"
                      />
                      <div id="start">
                        <i className="fa fa-download" aria-hidden="true" />
                        <div>Chọn hình ảnh hoặc kéo thả vào đây !</div>
                        <span id="file-upload-btn" className="btn btn-primary">
                          Chọn hình ảnh
                        </span>
                      </div>
                    </label>
                  </div>
                  <div className={Style.container_selected_images}>
                    {this.state.thumbnail_image.map((image, index) => (
                      <div key={index} className={Style.selected_images_c}>
                        <div>
                          
                          <img
                            className={Style.selected_images}
                            src={image}
                            alt=""
                          />
                          <Button
                            className={Style.selected_images_button}
                            onClick={() => this.handleRemoveImage(index)}
                          >
                            Xóa Ảnh
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <p className={Style.add_product_title}>Giá sản phẩm</p>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="item_price"
                      className="form-control"
                      id="item_price"
                      value={this.state.item_price}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="item_price">Giá</label>
                  </div>
                  <div className="form-check form-switch my-3">
                    <input
                      defaultChecked
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="itemIsOnSale"
                      checked={this.state.itemIsOnSale}
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Giảm giá sản phẩm
                    </label>
                  </div>

                  {itemIsOnSale && (
                    <div className="form-floating my-2">
                      <input
                        type="number"
                        className="form-control"
                        id="itemSaleRate"
                        name="itemSaleRate"
                        value={this.state.itemSaleRate}
                        onChange={this.handleInputChange}
                      />
                      <label htmlFor="item_price">
                        Tỉ Lệ Giảm Giá (% phần trăm)
                      </label>
                    </div>
                  )}
                  <div className="form-check form-switch my-4">
                    <input
                      defaultChecked
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="itemIsInStock"
                      checked={this.state.itemIsInStock}
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Còn hàng
                    </label>
                  </div>

                  <Form.Group controlId="formItemCategory">
                    <div className={Style.modifier_group_button}>
                      <div className={Style.add_product_title}>
                        Mục đồ ăn kèm
                      </div>
                    </div>

                    {this.state.modifier_group_keys?.map((key, index) => (
                      <div className="row  my-4">
                        <div key={index} className="form-floating col-lg-10">
                          <select
                            className="form-select"
                            value={key}
                            onChange={(e) =>
                              this.handleModifierGroupChangeAdditional(index, e)
                            }
                          >
                            <option value={""}></option>
                            {Object.keys(allGroupModifiers).map(
                              (modifierKey) => (
                                <option key={modifierKey} value={modifierKey}>
                                  {
                                    allGroupModifiers[modifierKey]
                                      .modifier_group_name
                                  }
                                </option>
                              )
                            )}
                          </select>

                          <label
                            className={Style.labelSelectModifier}
                            htmlFor={`floatingSelect${index + 1}`}
                          >
                            Mục đồ ăn kèm
                          </label>
                        </div>
                        <div className="col-lg-2">
                          <Button
                            className={Style.modifier_group_button_delete}
                            onClick={() => this.removeModifierGroup(index)}
                          >
                            <FaRegTrashAlt
                              className={Style.modifier_group_button_icon}
                            />
                          </Button>
                        </div>
                      </div>
                    ))}
                    <Button
                      variant="primary"
                      className={Style.modifier_group_button_add}
                      onClick={this.addModifierGroup}
                    >
                      <FaPlus />
                    </Button>
                  </Form.Group>
                  <Form.Group controlId="formItemCategory">
                    <div className={Style.add_product_title}>Danh Mục</div>

                    <div className="form-floating my-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        name="category_key"
                        value={this.state.category_key}
                        onChange={this.handleCategoryChange}
                      >
                        <option value={""}></option>
                        {Object.keys(allCategories).map((key) => (
                          <option key={key} value={key}>
                            {allCategories[key].name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="floatingSelect">Danh mục</label>
                    </div>

                    <div className="form-check form-switch mt-3">
                      <input
                        defaultChecked
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        name="isPublishedProduct"
                        checked={this.state.isPublishedProduct}
                        onChange={this.handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Hiển thị sản phẩm
                      </label>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Huỷ
              </Button>
              <Button
                className={Style.btn_primary}
                variant="primary"
                onClick={
                  this.state.isEditMode
                    ? this.handleUpdateItem
                    : this.handleClickItem
                }
              >
                {this.state.isEditMode ? "Cập nhật sản phẩm" : "Thêm sản phẩm"}
              </Button>
            </Modal.Footer>
          </Modal>
          <div className={`${Style.Pagination_div} mx-4`}>
            <div className={Style.paginationInfo}>
              Hiển thị từ {startRange} đến {endRange} sản phẩm
            </div>
            <div className={Style.pagination_container}>
              <Form.Group controlId="itemsPerPageSelect" className={Style.page_select}>
                <Form.Control
                  as="select"
                  onChange={this.handleItemsPerPageChange}
                  value={itemsPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">20</option>
                  <option value="20">50</option>
                </Form.Control>
              </Form.Group>
              <Pagination className={Style.pagination}>
                <Pagination.Prev onClick={this.handlePreviousPage} />
                {pageNumbers.map((number) => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => this.handlePageChange(number)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={this.handleNextPage} />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allItems: state.AllItemReducer.allItems,
    allCategories: state.AllCategoryReducer.allCategories,
    allGroupModifiers: state.AllGroupModifierReducer.allGroupModifiers,
    path: state.UploadImageReducer.path,
  };
};

const mapDispatchToProps = {
  UploadImage,
  updateItem,
  deleteItem,
  CreateItem,
  GetAllItems,
  GetAllCategories,
  GetAllGroupModifiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
