import { GET_ALL_BLOG } from "../../../store/Reducers/Blog";
import { GET_ALL_CATEGORY } from "../../../store/Reducers/Category";
import {
  GET_ALL_GROUP_MODIFIERS,
  GET_ALL_MODIFIERS,
} from "../../../store/Reducers/Modifier";
import { GET_ALL_ORDER } from "../../../store/Reducers/Order";
import { GET_ALL_ITEM } from "../../../store/Reducers/Product";
import {
  GET_ALL_ACCOUNT,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
} from "../../../store/Reducers/Profile";
import API_PATHS from "../API_Paths";
import axios from "axios";

const redirectCallback = () => {
  // Perform redirection here using your preferred method
  // For example:
  window.location.href = "/";
};
const GetAllModifiers = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_MODIFIERS)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_MODIFIERS,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetAllItems = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_ITEM)
      .then(({ data }) => {
        resolve(
          dispatch({
            type: GET_ALL_ITEM,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        );
      })
      .catch(reject);
  });

const GetAllGroupModifiers = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_GROUP_MODIFIERS)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_GROUP_MODIFIERS,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetAllCategories = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_CATEGORY)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_CATEGORY,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetAllBlogs = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_BLOG)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_BLOG,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetAllUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_ACCOUNT)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_ACCOUNT,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });
const GetAllOrder = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_ORDER)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_ORDER,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetUserProfile = (userID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_PROFILE_REQUEST });
    try {
      axios
        .get(`${API_PATHS.GET_USER_INFO}?userID=${userID}`)
        .then(({ data }) => {
          resolve(
            dispatch({
              type: GET_USER_PROFILE_SUCCESS,
              payload: data,
            })
          );
          localStorage.setItem("userInfo", JSON.stringify(data));
          redirectCallback();
        });
    } catch (err) {
      reject(err);
    }
  });
  const GetUserProfile2 = (userID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_PROFILE_REQUEST });
    try {
      axios
        .get(`${API_PATHS.GET_USER_INFO}?userID=${userID}`)
        .then(({ data }) => {
          resolve(
            dispatch({
              type: GET_USER_PROFILE_SUCCESS,
              payload: data,
            })
          );
     
        });
    } catch (err) {
      reject(err);
    }
  });
const GetRequests = {
  GetAllOrder,
  GetAllUser,
  GetAllBlogs,
  GetAllModifiers,
  GetAllItems,
  GetAllGroupModifiers,
  GetAllCategories,
  GetUserProfile,
  GetUserProfile2
};
export default GetRequests;
