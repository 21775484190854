
// Get
export const GET_ALL_ORDER = "GET_ALL_ORDER";

// Update
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';
export const UPDATE_ORDER_RESET = 'UPDATE_ORDER_RESET';

export const AllOrderReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ALL_ORDER:
        return { ...state, order: action.payload };
      default:
        return state;
    }
  };
export const updateOrderReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_ORDER_REQUEST:
        return {
          loading: true,
          success: false,
          error: null,
          orderInfo: null,
        };
      case UPDATE_ORDER_SUCCESS:
        return {
          loading: false,
          success: true,
          error: null,
          orderInfo: action.payload,
        };
      case UPDATE_ORDER_FAIL:
        return {
          loading: false,
          success: false,
          error: action.payload,
          orderInfo: null,
        };
      case UPDATE_ORDER_RESET:
        return {};
      default:
        return state;
    }
  };