export const GET_ALL_GROUP_MODIFIERS = "GET_ALL_GROUP_MODIFIERS";
export const GET_ALL_MODIFIERS = "GET_ALL_MODIFIERS";
export const MODIFIER_CREATE_REQUEST = "MODIFIER_CREATE_REQUEST";
export const MODIFIER_CREATE_SUCCESS = "MODIFIER_CREATE_SUCCESS";
export const MODIFIER_CREATE_FAIL = "MODIFIER_CREATE_FAIL";
export const MODIFIER_CREATE_RESET = "MODIFIER_CREATE_RESET";

export const GROUP_MODIFIER_REQUEST = "GROUP_MODIFIER_REQUEST";
export const GROUP_MODIFIER_SUCCESS = "GROUP_MODIFIER_SUCCESS";
export const GROUP_MODIFIER_FAIL = "GROUP_MODIFIER_FAIL";
export const GROUP_MODIFIER_RESET = "GROUP_MODIFIER_RESET";

export const DELETE_MODIFIER_REQUEST = "DELETE_MODIFIER_REQUEST";
export const DELETE_MODIFIER_SUCCESS = "DELETE_MODIFIER_SUCCESS";
export const DELETE_MODIFIER_FAIL = "DELETE_MODIFIER_FAIL";
export const DELETE_MODIFIER_RESET = "DELETE_MODIFIER_RESET";

export const DELETE_GROUP_MODIFIER_REQUEST = "DELETE_GROUP_MODIFIER_REQUEST";
export const DELETE_GROUP_MODIFIER_SUCCESS = "DELETE_GROUP_MODIFIER_SUCCESS";
export const DELETE_GROUP_MODIFIER_FAIL = "DELETE_GROUP_MODIFIER_FAIL";
export const DELETE_GROUP_MODIFIER_RESET = "DELETE_GROUP_MODIFIER_RESET";

export const UPDATE_MODIFIER_REQUEST = "UPDATE_MODIFIER_REQUEST";
export const UPDATE_MODIFIER_SUCCESS = "UPDATE_MODIFIER_SUCCESS";
export const UPDATE_MODIFIER_FAIL = "UPDATE_MODIFIER_FAIL";
export const UPDATE_MODIFIER_RESET = "UPDATE_MODIFIER_RESET";

export const UPDATE_GROUP_MODIFIER_REQUEST = "UPDATE_GROUP_MODIFIER_REQUEST";
export const UPDATE_GROUP_MODIFIER_SUCCESS = "UPDATE_GROUP_MODIFIER_SUCCESS";
export const UPDATE_GROUP_MODIFIER_FAIL = "UPDATE_GROUP_MODIFIER_FAIL";
export const UPDATE_GROUP_MODIFIER_RESET = "UPDATE_GROUP_MODIFIER_RESET";

export const CreateGroupModifierReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_MODIFIER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        modifyGroupInfo: null,
      };
    case GROUP_MODIFIER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        modifyGroupInfo: action.payload,
      };
    case GROUP_MODIFIER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        modifyGroupInfo: null,
      };
    case GROUP_MODIFIER_RESET:
      return {};
    default:
      return state;
  }
};
export const AllModifierReducer = (state = { allModifiers: {} }, action) => {
  switch (action.type) {
    case GET_ALL_MODIFIERS:
      return { ...state, allModifiers: action.payload };
    default:
      return state;
  }
};

export const AllGroupModifierReducer = (
  state = { allGroupModifiers: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_GROUP_MODIFIERS:
      return { ...state, allGroupModifiers: action.payload };
    default:
      return state;
  }
};
export const AllModifierCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MODIFIER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case MODIFIER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        orderInfo: action.payload,
      };
    case MODIFIER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MODIFIER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
export const deleteModifierReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MODIFIER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        modifierIds: null,
      };
    case DELETE_MODIFIER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        modifierIds: action.payload,
      };
    case DELETE_MODIFIER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        modifierIds: null,
      };
    case DELETE_MODIFIER_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteGroupModifierReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_GROUP_MODIFIER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        modifyGroupIds: null,
      };
    case DELETE_GROUP_MODIFIER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        modifyGroupIds: action.payload,
      };
    case DELETE_GROUP_MODIFIER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        modifyGroupIds: null,
      };
    case DELETE_GROUP_MODIFIER_RESET:
      return {};
    default:
      return state;
  }
};

export const updateModifierReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MODIFIER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        modifierInfo: null,
      };
    case UPDATE_MODIFIER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        modifierInfo: action.payload,
      };
    case UPDATE_MODIFIER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        modifierInfo: null,
      };
    case UPDATE_MODIFIER_RESET:
      return {};
    default:
      return state;
  }
};

export const updateGroupModifierReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_GROUP_MODIFIER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        groupModifierInfo: null,
      };
    case UPDATE_GROUP_MODIFIER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        groupModifierInfo: action.payload,
      };
    case UPDATE_GROUP_MODIFIER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        groupModifierInfo: null,
      };
    case UPDATE_GROUP_MODIFIER_RESET:
      return {};
    default:
      return state;
  }
};
