import { combineReducers } from "redux";
import {
  AllItemReducer,
  CreateItemReducer,
  UpdateItemReducer,
  UploadImageReducer,
  deleteItemReducer,
} from "../Reducers/Product";
import {
  AllUserReducer,
  GetUserProfileReducer,
  deleteAccountReducer,
  loginReducer,
} from "../Reducers/Profile";
import { AllOrderReducer } from "../Reducers/Order";
import {
  AllCategoryReducer,
  CreateCategoryReducer,
  deleteCategoryReducer,
  updateCategoryReducer,
} from "../Reducers/Category";
import {
  AllGroupModifierReducer,
  AllModifierCreateReducer,
  AllModifierReducer,
  CreateGroupModifierReducer,
  deleteGroupModifierReducer,
  deleteModifierReducer,
  updateGroupModifierReducer,
  updateModifierReducer,
} from "../Reducers/Modifier";
import {
  AllBlogReducer,
  CreateBlogReducer,
  deleteBlogReducer,
  updateBlogReducer,
} from "../Reducers/Blog";

const rootReducer = combineReducers({
  // Users
  userLogin: loginReducer,
  AllUserInfo: GetUserProfileReducer,
  AllUserReducer,
  deleteAccountReducer,

  // Orders
  AllOrderReducer,

  // Products
  deleteItemReducer,
  UploadImageReducer,
  AllItemReducer,
  CreateItemReducer,
  UpdateItemReducer,

  // Categories
  updateCategoryReducer,
  deleteCategoryReducer,
  AllCategoryReducer,
  CreateCategoryReducer,

  // Modifiers
  updateGroupModifierReducer,
  updateModifierReducer,
  deleteGroupModifierReducer,
  deleteModifierReducer,
  AllGroupModifierReducer,
  AllModifierReducer,
  AllModifierCreateReducer,
  CreateGroupModifierReducer,

  // Blog
  updateBlogInfo: updateBlogReducer,
  deleteBlogReducer,
  createBlog: CreateBlogReducer,
  AllBlogReducer,
});

export default rootReducer;
