export const GET_ALL_ACCOUNT = "GET_ALL_ACCOUNT";

// CREATE USER
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_RESET = "CREATE_USER_RESET";

// GET USER
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

// DELETE USER
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";
export const DELETE_ACCOUNT_RESET = "DELETE_ACCOUNT_RESET";
// LOGIN
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";

// UPDATE USER
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";

const initialState = {
  loading: false,
  success: false,
  error: null,
  lastError: null,
  token: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        token: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const AllUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ACCOUNT:
      return { ...state, allUser: action.payload };
    default:
      return state;
  }
};

export const GetUserProfileReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return {
        loading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload,
      };
    case GET_USER_PROFILE_FAILED:
      return {
        loading: false,
        success: false,
      };
    default:
      return {};
  }
};

export const CreateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        userInfo: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        userInfo: action.payload,
      };
    case CREATE_USER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        userInfo: null,
      };
    case CREATE_USER_RESET:
      return {};
    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        userInfo: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        userInfo: action.payload,
      };
    case UPDATE_USER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        userInfo: null,
      };
    case UPDATE_USER_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ACCOUNT_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        accountInfo: null,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        accountInfo: action.payload,
      };
    case DELETE_ACCOUNT_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        accountInfo: null,
      };
    case DELETE_ACCOUNT_RESET:
      return {};
    default:
      return state;
  }
};
