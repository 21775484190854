export const STATUS_CODE = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};
export const FormatCurrencyToVND = (price) =>
  new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(price);
export const formatCreatedAt = (createdAt) => {
  const date = new Date(createdAt);
  const day = date.getDate();

  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};
export const _updatePageTitleAndDescription = ({
  description = "",
  title = "",
}) => {
  if (description.length > 0)
    document.getElementsByTagName("meta")["description"].content = description;
  if (title.length > 0) document.title = title;
};
const removeVietnameseAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
};
export const TitleToSlug = (title) => {
  const withoutVietnameseAccents = removeVietnameseAccents(title);
  const slug = withoutVietnameseAccents
    .toLowerCase() // Convert to lowercase
    .replace(/[^\w\s]/gi, "") // Remove special characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .trim(); // Remove leading and trailing whitespace (if any)
    return slug.replace(/-+$/, "");
};