import React from "react";
import { IoNewspaperOutline } from "react-icons/io5";
import { BiHomeCircle, BiFoodMenu, BiCategory } from "react-icons/bi";
import { FaRegCalendarAlt, FaUsersCog } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";
export const NAV_LINK_MENU = [
  {
    name: "Bảng điều khiển",
    route: "/",
    icon: <BiHomeCircle />,
    role: ["owner"],
  },
  {
    name: "Quản lý đơn hàng",
    route: "/Order",
    icon: <FaUsersCog />,
    role: ["owner", "employee"],
  },
  {
    name: "Quản lí danh mục",
    route: "/Category",
    icon: <FaRegCalendarAlt />,
    role: ["owner", "employee"],
  },
  {
    name: "Quản lý sản phẩm",
    route: "/Item",
    icon: <BiFoodMenu />,
    role: ["owner", "employee"],
  },
  {
    name: "Mục đồ ăn kèm",
    route: "/ModifierGroup",
    icon: <BiCategory />,
    role: ["owner", "employee"],
  },
  {
    name: "Đồ ăn kèm",
    route: "/Modifiers",
    icon: <TbFileInvoice />,
    role: ["owner", "employee"],
  },
  {
    name: "Quản lý nhân viên",
    route: "/User",
    icon: <FaUsersCog />,
    role: ["owner"],
  },
  {
    name: "Quản lý bài viết",
    route: "/Blog",
    icon: <IoNewspaperOutline />,
    role: ["owner", "employee"],
  },
];
