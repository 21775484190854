// GET
export const GET_ALL_BLOG = "GET_ALL_BLOG";

// CREATE
export const CREATE_BLOG_RESET = "CREATE_BLOG_RESET";
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST";

// DELETE

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const DELETE_BLOG_RESET = "DELETE_BLOG_RESET";

// Update
export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";
export const UPDATE_BLOG_RESET = "UPDATE_BLOG_RESET";

const stateDefault = {
  loading: false,
  success: false,
  blogIds: null,
  blogInfo: {},
};

export const AllBlogReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_ALL_BLOG:
      return { ...state, allBlogs: action.payload };
    default:
      return state;
  }
};

export const updateBlogReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case UPDATE_BLOG_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        blogInfo: null,
      };
    case UPDATE_BLOG_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        blogInfo: action.payload,
      };
    case UPDATE_BLOG_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        blogInfo: null,
      };
    case UPDATE_BLOG_RESET:
      return stateDefault;
    default:
      return state;
  }
};

export const CreateBlogReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case CREATE_BLOG_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        blogInfo: null,
      };
    case CREATE_BLOG_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        blogInfo: action.payload,
      };
    case CREATE_BLOG_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        blogInfo: null,
      };
    case CREATE_BLOG_RESET:
      return stateDefault;
    default:
      return state;
  }
};

export const deleteBlogReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case DELETE_BLOG_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        blogIds: null,
      };
    case DELETE_BLOG_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        blogIds: action.payload,
      };
    case DELETE_BLOG_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        blogIds: null,
      };
    case DELETE_BLOG_RESET:
      return stateDefault;
    default:
      return state;
  }
};
