export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";


export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";
export const CREATE_CATEGORY_RESET = "CREATE_CATEGORY_RESET";


export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";
export const DELETE_CATEGORY_RESET = "DELETE_CATEGORY_RESET";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";
export const UPDATE_CATEGORY_RESET = "UPDATE_CATEGORY_RESET";


export const AllCategoryReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ALL_CATEGORY:
        return { ...state, allCategories: action.payload };
      default:
        return state;
    }
  };
export const updateCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CATEGORY_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        categoryInfo: null,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        categoryInfo: action.payload,
      };
    case UPDATE_CATEGORY_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        categoryInfo: null,
      };
    case UPDATE_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CATEGORY_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        categoryInfo: null,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        categoryInfo: action.payload,
      };
    case DELETE_CATEGORY_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        categoryInfo: null,
      };
    case DELETE_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const CreateCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        categoryInfo: null,
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        categoryInfo: action.payload,
      };
    case CREATE_CATEGORY_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        categoryInfo: null,
      };
    case CREATE_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};
