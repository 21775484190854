import React from "react";
import { connect } from "react-redux";
import { Admin } from "../../lib/services";
import { Form, Table, Button, Modal, Pagination } from "react-bootstrap";
import Style from "./style.module.scss";
import moment from "moment";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { CiEdit, CiSearch } from "react-icons/ci";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import "./Uploader.scss";

import {
  TitleToSlug,
  _updatePageTitleAndDescription,
} from "../../utils/systems";
const { CreateBlog, deleteBlog, updateBlog, UploadImage } = Admin.PostRequests;
const { GetAllBlogs, GetUserProfile } = Admin.GetRequests;
class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogId: null,
      title_blog: "",
      body_blog: "",
      author: "",
      newTextBlog: "",
      searchQuery: "",
      selectedBlog: [],
      selectAll: false,
      isEditMode: false,
      isLoadingEditor: true,
      isHiddenPost: false,
      lastEditedTimeStamps: null,
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      currentPage: 1,
      itemsPerPage: 6,
      slug: "",
      thumbnail_images: "",
    };
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  editorRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { success: SuccessCreatePost } = this.props.createBlog;
    const { success: PrevCreatePost } = prevProps.createBlog;
    const { success: prevSuccess = false } = prevProps.updateBlogInfo || {};
    const { success = false } = this.props.updateBlogInfo || {};
    // Check if success status changed from false to true
    if ((success && !prevSuccess) || SuccessCreatePost !== PrevCreatePost) {
      this.setState({
        showModal: false,
        title_blog: "",
        body_blog: "",
        author: "",
        slug: "",
      });
      this.props.GetAllBlogs();
    }
  }
  componentDidMount() {
    this.props.GetAllBlogs();
    _updatePageTitleAndDescription({
      description: "Trang mục của blog",
      title: "blog món ăn",
    });
  }
  componentWillUnmount() {
    this.props.GetAllBlogs();
  }
  handleSearchChange = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  handlePageChange = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };
  handlePreviousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };
  handleNextPage = () => {
    const { currentPage, itemsPerPage } = this.state;
    const { allBlogs } = this.props;
    const totalPages = Math.ceil(Object.keys(allBlogs).length / itemsPerPage);
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };
  getTotalItems = () => {
    const { allBlogs } = this.props;
    return allBlogs ? Object.keys(allBlogs).length : 0;
  };

  getStartRange = () => {
    const { currentPage, itemsPerPage } = this.state;
    const totalItems = this.getTotalItems();
    return totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  };

  getEndRange = () => {
    const { currentPage, itemsPerPage } = this.state;
    const totalItems = this.getTotalItems();
    const calculatedEndRange = currentPage * itemsPerPage;
    return Math.min(calculatedEndRange, totalItems);
  };
  ValidateTitleBlog = (title) => {
    return title.length <= 60;
  };
  handleCreateBlog = async () => {
    const { title_blog, body_blog, userInfo, isHiddenPost, thumbnail_images } =
      this.state;
    const { allBlogs = {} } = this.props;
    if (!title_blog || !body_blog || !thumbnail_images) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Vui lòng điền đầy đủ thông tin",
      });
      return;
    }
    const isTitleExist = Object.values(allBlogs).some(
      (blog) => blog.title_blog === title_blog
    );
    if (isTitleExist) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Tiêu đề blog đã tồn tại",
      });
      return;
    }
    const blogInfo = {
      slug: TitleToSlug(title_blog),
      title_blog,
      body_blog,
      isHiddenPost,
      author: userInfo?.name,
      thumbnail_images,
      time_stamps: moment().format("MMM Do YY"),
    };
    if (!this.ValidateTitleBlog(title_blog)) {
      // Display an error message or handle invalid input
      return Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Tiêu đề không được quá 60 từ !",
      });
    }
    try {
      Swal.fire({
        title: "Đang xử lý...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await this.props.CreateBlog(blogInfo);
      await this.props.GetAllBlogs();
      Swal.fire({
        icon: "success",
        title: "Thành công!",
        text: "Đã tạo bài viết thành công.",
      });
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Đã xảy ra lỗi khi tạo bài viết. Vui lòng thử lại sau.",
      });
    }
  };

  handleClickUpdateBlog = (blogId) => {
    this.setState({ showModal: true, isEditMode: true, blogId });
    const { allBlogs } = this.props;
    const blogDetail = allBlogs[blogId];

    if (blogDetail) {
      this.setState({
        thumbnail_images: blogDetail.thumbnail_images,
        body_blog: blogDetail.body_blog,
        title_blog: blogDetail.title_blog,
        isHiddenPost: blogDetail.isHiddenPost,
        time_stamps: blogDetail.time_stamps,
        author: blogDetail.author,
        slug: TitleToSlug(blogDetail.title_blog),
      });
    }
  };
  handleItemsPerPageChange = (event) => {
    const itemsPerPage = parseInt(event.target.value, 10);
    this.setState({
      itemsPerPage,
      currentPage: 1, 
    });
  };
  handleUpdateBlog = async() => {
    const {
      title_blog,
      body_blog,
      blogId,
      isHiddenPost,
      author,
      slug,
      thumbnail_images,
    } = this.state;

    const blogInfo = {
      thumbnail_images,
      title_blog,
      body_blog,
      isHiddenPost,
      slug,
      time_stamps: moment().format("MMM Do YY"),
      author,
    };
    if (!this.ValidateTitleBlog(title_blog)) {
      return Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Tiêu đề không được quá 60 từ !",
      });
    }
    try {
      Swal.fire({
        title: "Đang xử lý...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await this.props.updateBlog(blogId, blogInfo);
      Swal.fire({
        icon: "success",
        title: "Thành công!",
        text: "Cập nhật bài viết thành công.",
      });
      this.setState({
        body_blog: "",
        title_blog: "",
      });
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Đã xảy ra lỗi khi cập nhật bài viết. Vui lòng thử lại sau.",
      });
    }
  };

  handleSelectAllChange = () => {
    this.setState((prevState) => {
      const { allBlogs } = this.props;
      const allItemIds = Object.keys(allBlogs);

      const selectedBlog = prevState.selectAll ? [] : allItemIds;

      return { selectedBlog, selectAll: !prevState.selectAll };
    });
  };

  handleDeleteBlog = async() => {
    const { selectedBlog } = this.state;
  
    try {
      Swal.fire({
        title: "Đang xử lý...",
        allowOutsideClick: false,
      });
      Swal.fire({
        icon: "success",
        title: "Thành công!",
        text: "Xoá bài viết thành công.",
      });
      await this.props.deleteBlog(selectedBlog);
      await this.props.GetAllBlogs();
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Đã xảy ra lỗi khi xoá bài viết. Vui lòng thử lại sau.",
      });
    }
   
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleShowModal = () => {
    this.setState({ showModal: true, isEditMode: false });
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false,
      isEditMode: false,
      title_blog: "",
      body_blog: "",
      thumbnail_images: "",
    });
  };

  handleTogglePublicPost = () => {
    this.setState((prevState) => ({
      isHiddenPost: !prevState.isHiddenPost,
    }));
  };

  handleCheckboxChange = (blogId) => {
    const { selectedBlog } = this.state;
    if (selectedBlog.includes(blogId)) {
      this.setState({
        selectedBlog: selectedBlog.filter((id) => id !== blogId),
      });
    } else {
      this.setState({
        selectedBlog: [...selectedBlog, blogId],
      });
    }
  };
  renderTableRows() {
    const { allBlogs = {} } = this.props;
    const { selectedBlog, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return Object.keys(allBlogs)
      .reverse()
      .filter((key) => {
        const title = allBlogs[key]?.title_blog || "";
        return title
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase());
      })
      .slice(indexOfFirstItem, indexOfLastItem)
      .map((key) => {
        const blog = allBlogs[key];
        const truncatedTitle =
          blog.title_blog.length > 50
            ? blog.title_blog.substring(0, 40) + "..."
            : blog.title_blog;
        return (
          <tr key={blog.title_blog}>
            <td>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedBlog.includes(key)}
                onChange={() => this.handleCheckboxChange(key)}
              />
            </td>
            <td>{truncatedTitle}</td>
            <td>{blog.author}</td>
            <td>
              Lần chỉnh sửa cuối, <span>{blog.time_stamps}</span>
            </td>
            <td>
              <span className="badge rounded-pill bg-primary">
                {blog.isHiddenPost ? "Ẩn bài viết" : "Hiện bài viêt"}
              </span>
            </td>
            <td>
              <div className={Style.btn_action_group}>
                <button
                  type="button"
                  className={`${Style.item_action} btn`}
                  onClick={() => this.handleClickUpdateBlog(key)}
                >
                  <CiEdit />
                </button>
              </div>
            </td>
          </tr>
        );
      });
  }
  handleImageUpload = async (file) => {
    if (file.size > 500 * 1024) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Hình ảnh phải nhỏ hơn 500kb.",
      });
      return;
    }
    const reader = new FileReader();

    reader.onloadend = async () => {
      const path = reader.result;
      try {
        Swal.fire({
          title: "Đang xử lý...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await this.props.UploadImage(path);

        Swal.fire({
          icon: "success",
          title: "Thành công!",
          text: "Hình ảnh đã được tải lên thành công.",
        });
        this.setState({
          thumbnail_images: this.props.path,
        });
      } catch (error) {
        console.error("Đã xảy ra lỗi khi tải lên hình ảnh:", error);

        Swal.fire({
          icon: "error",
          title: "Lỗi!",
          text: "Đã xảy ra lỗi khi tải lên hình ảnh. Vui lòng thử lại sau.",
        });
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  handleLoadingEditor = () => {
    const { isLoadingEditor } = this.state;
    if (isLoadingEditor) {
      return (
        <React.Fragment>
          <div className="d-flex justify-content-center align-items-center">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
            <span className="mx-2">Đang tải block editor....</span>
          </div>
        </React.Fragment>
      );
    }
  };

  handleEditorChange = (evt, editor) => {
    this.setState({
      body_blog: evt,
    });
  };

  render() {
    const {
      loadingCreateBlogInfo,
      loadingUpdateBlogInfo,
      allBlogs = {},
    } = this.props;
    const { selectAll, currentPage, itemsPerPage } = this.state;
    const startRange = this.getStartRange();
    const endRange = this.getEndRange();
    const pageNumbers = [];
    for (
      let number = 1;
      number <= Math.ceil(Object.keys(allBlogs).length / itemsPerPage);
      number++
    ) {
      pageNumbers.push(number);
    }
    return (
      <div className={Style.table_container}>
        <h2>Quản lí bài viết</h2>
        <div className={Style.header_table}>
          <div className={Style.header_table_container}>
            <div className={`${Style.app_search} d-none d-lg-block mx-3`}>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tìm kiếm..."
                  value={this.state.searchQuery}
                  onChange={(e) => this.handleSearchChange(e.target.value)}
                />
                <CiSearch />
              </div>
            </div>
            <div className={Style.header_btn_group}>
              <button
                className={Style.header_add_button}
                onClick={this.handleShowModal}
              >
                <div>
                  <FaPlus />
                  <span>Thêm bài viết</span>
                </div>
              </button>
              <button
                className={Style.header_delete_button}
                onClick={this.handleDeleteBlog}
              >
                <div>
                  <FaRegTrashAlt />
                  <span>Xoá</span>
                </div>
              </button>
            </div>
          </div>

          <Table hover className={Style.table}>
            <thead>
              <tr>
                <th>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={selectAll}
                    onChange={this.handleSelectAllChange}
                  />
                </th>
                <th>Tiêu Đề</th>
                <th>Tác giả</th>
                <th>Ngày đăng bài</th>
                <th>Hiện bài biết</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>{this.renderTableRows()}</tbody>
          </Table>
          <div className={`${Style.Pagination_div} mx-4`}>
            <div className={Style.paginationInfo}>
              Hiển thị từ {startRange} đến {endRange} sản phẩm
            </div>
            <div className={Style.pagination_container}>
              <Form.Group controlId="itemsPerPageSelect" className={Style.page_select}>
                <Form.Control
                  as="select"
                  onChange={this.handleItemsPerPageChange}
                  value={itemsPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">20</option>
                  <option value="20">50</option>
                </Form.Control>
              </Form.Group>
              <Pagination className={Style.pagination}>
                <Pagination.Prev onClick={this.handlePreviousPage} />
                {pageNumbers.map((number) => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => this.handlePageChange(number)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={this.handleNextPage} />
              </Pagination>
            </div>
          </div>
          <Modal
            className={Style.modal}
            fullscreen
            show={this.state.showModal}
            onHide={this.handleCloseModal}
          >
            <Modal.Header closeButton>
              <div className="d-flex align-items-center justify-content-between">
                <Modal.Title>
                  {this.state.isEditMode ? "Sửa bài viết" : "Thêm bài viết"}
                </Modal.Title>
                <Modal.Header>
                  <div className="form-check form-switch mx-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={!this.state.isHiddenPost}
                      onChange={this.handleTogglePublicPost}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      {this.state.isHiddenPost
                        ? "Ẩn bài viết"
                        : "Hiện bài viết"}
                    </label>
                  </div>
                </Modal.Header>
              </div>
            </Modal.Header>
            <Modal.Body className={Style.modalBodyBlog}>
              <Form className="row">
                <div className="col-12 col-lg-10 mb-5">
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingInputValue"
                      value={this.state.title_blog}
                      onChange={(e) =>
                        this.setState({ title_blog: e.target.value })
                      }
                      name="demo"
                      placeholder="demo"
                    />
                    <label htmlFor="floatingInputValue">Tiêu đề</label>
                  </div>
                  {this.handleLoadingEditor()}
                  <Editor
                    apiKey="a8rypabz4bdnu1jzs5t05ooq3kmtoeh8z38ctgqdlxaqsb0p"
                    onInit={(evt, editor) => {
                      this.setState({
                        isLoadingEditor: false,
                        // body_blog: editor.getContent({ format: "text" }),
                      });
                    }}
                    value={this.state.body_blog}
                    onEditorChange={(newValue, editor) =>
                      this.handleEditorChange(newValue, editor)
                    }
                    init={{
                      height: 500,
                      menubar: false,
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    }}
                  />
                </div>
                <div
                  className={`${Style.file_upload_container} col-12 col-lg-2`}
                >
                  <div id="file-upload-form" className="uploader">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Hình ảnh</span>
                      <small className="fw-light">Add media from URL</small>
                    </div>
                    <input
                      onChange={(e) =>
                        this.handleImageUpload(e.target.files[0])
                      }
                      id="file-upload"
                      type="file"
                      name="fileUpload"
                      accept="image/*"
                    />

                    <label htmlFor="file-upload" id="file-drag">
                      <img
                        id="file-image"
                        alt="Preview"
                        src={this.state.thumbnail_images}
                      />
                      <div id="start">
                        <i className="fa fa-download" aria-hidden="true" />
                        <div>Chọn hình ảnh !</div>
                        <span id="file-upload-btn" className="btn btn-primary">
                          Chọn hình ảnh
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Huỷ
              </Button>
              <Button
                className={Style.btn_primary}
                variant="primary"
                onClick={
                  this.state.isEditMode
                    ? this.handleUpdateBlog
                    : this.handleCreateBlog
                }
                disabled={
                  loadingUpdateBlogInfo || loadingCreateBlogInfo ? true : false
                }
              >
                {loadingUpdateBlogInfo ||
                  (loadingCreateBlogInfo && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ))}
                <span className="mx-2">
                  {this.state.isEditMode ? "Cập nhật bài viết" : "Đăng bài"}
                </span>
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allBlogs: state.AllBlogReducer.allBlogs,
    createBlog: state.createBlog,
    updateBlogInfo: state.updateBlogInfo.blogInfo,
    loadingUpdateBlogInfo: state.updateBlogInfo.loading,
    loadingCreateBlogInfo: state.createBlog.loading,
    path: state.UploadImageReducer.path,
    loading: state.UploadImageReducer.loading,
    all: state.UploadImageReducer,
  };
};

const mapDispatchToProps = {
  CreateBlog,
  GetAllBlogs,
  deleteBlog,
  GetUserProfile,
  updateBlog,
  UploadImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
