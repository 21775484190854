import React, { Component } from "react";
import Style from "./style.module.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { connect } from "react-redux";
import { Admin } from "../../lib/services";
import Swal from "sweetalert2";
const { GetUserProfile2 } = Admin.GetRequests;
const { updateUser } = Admin.PostRequests;

class Profile extends Component {
  state = {
    showPassword: false,
    showConfirmPassword: false,
    isEdited: false,
    user_name: "",
    password: "",
    name: "",
    phone: null,
    email: "",
    newPassword: "",
    confirmPassword: "",
  };

  async componentDidMount() {
    const userId = JSON.parse(localStorage.getItem("userId"));
    await this.props.GetUserProfile2(userId);
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleClickEditProfile = () => {
    const { userInfo = {} } = this.props;

    this.setState((prevState) => ({
      isEdited: !prevState.isEdited,
      user_name: userInfo.user_name,
      name: userInfo.name,
      password: userInfo.password,
      phone: userInfo.phone,
      email: userInfo.email,
    }));
  };

  handleUpdatePassword = async () => {
    const {  newPassword, confirmPassword } = this.state;
    const { userInfo = {} } = this.props;

    const userId = JSON.parse(localStorage.getItem("userId"));
    if (!newPassword || !confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Vui lòng điền đầy đủ thông tin bắt buộc.",
      });
      return;
    }

    if (newPassword !==  userInfo.password) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Mật khẩu cũ không chính xác.",
      });
      return;
    }

    if (newPassword === confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Mật khẩu mới và mật khẩu nhập lại không khớp.",
      });
      return;
    }

    const updatedUserInfo = {
      password: confirmPassword,
    };

    try {
      await this.props.updateUser(userId, updatedUserInfo);
      Swal.fire({
        icon: "success",
        title: "Thành công!",
        text: "Thông tin người dùng đã được cập nhật thành công.",
      });

      await this.props.GetUserProfile2(userId);
    } catch (error) {
      console.error("Đã xảy ra lỗi khi cập nhật người dùng:", error);
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Đã xảy ra lỗi khi cập nhật người dùng. Vui lòng thử lại sau.",
      });
    }
  };
  handleNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  // Hàm xử lý sự kiện khi thay đổi giá trị mật khẩu xác nhận
  handleConfirmPasswordChange = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };

  handleUpdateUser = async () => {
    const { user_name, name, password, phone, email } = this.state;
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (!user_name || !name || !password || !phone || !email) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Vui lòng điền đầy đủ thông tin bắt buộc.",
      });
      return;
    }
    if (phone.length !== 10 || !/^\d+$/.test(phone)) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Số điện thoại phải chứa đúng 10 chữ số.",
      });
      return;
    }

    if (!/^\w+([.-]?\w+)*@gmail\.com$/.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Email phải theo định dạng của Gmail.",
      });
      return;
    }

    const updatedUserInfo = {
      user_name,
      name,
      password,
      phone,
      email,
    };

    try {
      await this.props.updateUser(userId, updatedUserInfo);
      Swal.fire({
        icon: "success",
        title: "Thành công!",
        text: "Thông tin người dùng đã được cập nhật thành công.",
      });

      await this.props.GetUserProfile2(userId);
      this.setState((prevState) => ({
        isEdited: !prevState.isEdited,
      }));
    } catch (error) {
      console.error("Đã xảy ra lỗi khi cập nhật người dùng:", error);
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Đã xảy ra lỗi khi cập nhật người dùng. Vui lòng thử lại sau.",
      });
    }
  };
  render() {
    const { isEdited } = this.state;
    const { userInfo = {} } = this.props;
    return (
      <React.Fragment>
        <h2>Thông tin tài khoản</h2>
        <div className={`${Style.ProfileInnner} row`}>
          <div className="col-xl-4 col-lg-5 col-md-5 ">
            <div className="card mb-4">
              <div className="card-body">
                <div className="user-avatar-section">
                  <div className=" d-flex align-items-center flex-column">
                    <img
                      className="img-fluid rounded mb-3 mt-4"
                      src="https://demos.themeselection.com/materio-bootstrap-html-admin-template/assets/img/avatars/10.png"
                      height={120}
                      width={120}
                      alt="User avatar"
                    />
                    <div className="user-info text-center">
                      <h4> {userInfo.name}</h4>
                      <span className="badge bg-label-danger rounded-pill">
                        {userInfo.role}
                      </span>
                    </div>
                  </div>
                </div>
                <h5 className="pb-3 border-bottom mb-3">Chi tiết</h5>
                <div className="info-container">
                  <ul className="list-unstyled mb-4">
                    <li className="mb-3">
                      <span className="h6">Name:</span>
                      {isEdited ? (
                        <span className="input-group input-group-sm">
                          <input
                            type="text"
                            value={this.state.name}
                            className="form-control"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            name="name"
                            onChange={this.handleInputChange}
                          />
                        </span>
                      ) : (
                        <span> {userInfo.name}</span>
                      )}
                    </li>
                    <li className="mb-3">
                      <span className="h6">Username:</span>
                      {isEdited ? (
                        <span className="input-group input-group-sm">
                          <input
                            type="text"
                            value={this.state.user_name}
                            className="form-control"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={this.handleInputChange}
                            name="user_name"
                          />
                        </span>
                      ) : (
                        <span> {userInfo.user_name}</span>
                      )}
                    </li>
                    <li className="mb-3">
                      <span className="h6">Email:</span>
                      {isEdited ? (
                        <span className="input-group input-group-sm">
                          <input
                            type="text"
                            value={this.state.email}
                            className="form-control"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={this.handleInputChange}
                            name="email"
                          />
                        </span>
                      ) : (
                        <span> {userInfo.email}</span>
                      )}
                    </li>
                    <li className="mb-3">
                      <span className="h6">Role:</span>

                      <span> {userInfo.role}</span>
                    </li>

                    <li className="mb-3">
                      <span className="h6">Contact:</span>
                      {isEdited ? (
                        <span className="input-group input-group-sm">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.phone}
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={this.handleInputChange}
                            name="phone"
                          />
                        </span>
                      ) : (
                        <span> {userInfo.phone}</span>
                      )}
                    </li>
                  </ul>
                  <div className={Style.editedProfile}>
                    <button
                      onClick={
                        isEdited
                          ? this.handleUpdateUser
                          : this.handleClickEditProfile
                      }
                      className={`btn ${
                        isEdited ? "btn-warning" : "btn-primary"
                      }`}
                    >
                      {isEdited ? "Cập nhật" : "Sửa"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 col-md-7">
            <div className="card mb-4">
              <h5 className="card-header">Thay đổi mật khẩu</h5>
              <div className="card-body">
                <div className={Style.formUpdate}>
                  <div className="alert alert-warning" role="alert">
                    <h6 className="alert-heading mb-1">
                      Hãy đảm bảo yêu cầu được đáp ứng.
                    </h6>
                    <span>Điền it nhất 8 ký tự, in hoa &amp; ký tự</span>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                      <div className="input-group input-group-merge">
                        <div className="form-floating form-floating-outline">
                          <input
                            className="form-control"
                            type={this.state.showPassword ? "text" : "password"}
                            id="newPassword"
                            name="newPassword"
                            value={this.state.newPassword}
                            onChange={this.handleNewPasswordChange}
                            placeholder="············"
                          />
                          <label htmlFor="newPassword">Mật khẩu cũ</label>
                        </div>
                        <button
                          type="button"
                          className={`${Style.eye_flash} btn btn-link position-absolute h-100 end-0 top-0`}
                          id="password-addon"
                          onClick={this.togglePasswordVisibility}
                        >
                          {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                      <div className="input-group input-group-merge">
                        <div className="form-floating form-floating-outline">
                          <input
                            className="form-control"
                            type={
                              this.state.showConfirmPassword
                                ? "text"
                                : "password"
                            }
                            name="confirmPassword"
                            id="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={this.handleConfirmPasswordChange}
                            placeholder="············"
                          />
                          <label htmlFor="confirmPassword">
                            Nhập lại mật khẩu mới
                          </label>
                        </div>
                        <button
                          type="button"
                          className={`${Style.eye_flash} btn btn-link position-absolute h-100 end-0 top-0`}
                          id="password-addon"
                          onClick={this.toggleConfirmPasswordVisibility}
                        >
                          {this.state.showConfirmPassword ? (
                            <FaEyeSlash />
                          ) : (
                            <FaEye />
                          )}
                        </button>
                      </div>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className={`${Style.buttonUpdate} btn btn-primary me-2 `}
                        onClick={this.handleUpdatePassword}
                      >
                        Thay đổi mật khẩu
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.AllUserInfo.userInfo,
  };
};

const mapDispatchToProps = {
  GetUserProfile2,
  updateUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
