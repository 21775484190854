import React from "react";
import "./App.css";
import "./responsive.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRouter from "./routes/PrivateRouter";
import Category from "./components/ManageCategory";
import Blog from "./components/ManageBlog";
import Item from "./components/ManageItem";
import ModifierGroup from "./components/ManageModifierGroup";
import Modifiers from "./components/ManageModifiers";
import NotFound from "./views/NotFound";
import Dashboard from "./components/Dashboard";
import Login from "./views/Login";
import User from "./components/ManageUser";
import Order from "./components/ManageOrder";
import Profile from "./views/Profile";
import { connect } from "react-redux";
import { Admin } from "./lib/services";

const { GetUserProfile } = Admin.GetRequests;

class App extends React.Component {
  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const { role } = userInfo || {};
    if (userInfo) {
      this.setState({ role });
    }
  }

  handleAuthProfile = () => {
    const { userLogin = {} } = this.props;
    return Object.keys(userLogin).length > 0 ? (
      <Redirect to="/" component={Dashboard} exact />
    ) : (
      <Route path="/login" component={Login} exact />
    );
  };

  render() {
    const { role } = this.state || {};
    return (
      <Router>
        <Switch>
          {role === "owner" && (
            <PrivateRouter path="/" component={Dashboard} exact />
          )}
          {role === "employee" && (
            <PrivateRouter path="/" component={Order} exact />
          )}
          <PrivateRouter path="/Category" component={Category} exact />
          <PrivateRouter path="/Blog" component={Blog} exact />
          <PrivateRouter path="/Item" component={Item} exact />
          {role === "owner" && (
            <PrivateRouter path="/User" component={User} exact />
          )}
           {role === "employee" && (
            <PrivateRouter path="/User" component={Order} exact />
          )}
          <PrivateRouter
            path="/ModifierGroup"
            component={ModifierGroup}
            exact
          />
          <PrivateRouter path="/Modifiers" component={Modifiers} exact />
          <PrivateRouter path="/Order" component={Order} exact />
          <PrivateRouter path="/profile" component={Profile} exact />
          {this.handleAuthProfile()}

          <PrivateRouter path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userLogin: state.userLogin.userInfo,
    userInfoDetail: state.AllUserInfo.userInfo,
  };
};
const mapDispatchToProps = {
  GetUserProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
