export const GET_ALL_ITEM = "GET_ALL_ITEM";
export const GET_ALL_ITEM_REQUEST = "GET_ALL_ITEM_REQUEST";
export const GET_ALL_ITEM_FAIL = "GET_ALL_ITEM_FAIL";

export const CREATE_ITEM_REQUEST = "CREATE_ITEM_REQUEST";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAIL = "CREATE_ITEM_FAIL";
export const CREATE_ITEM_RESET = "CREATE_ITEM_RESET";

export const UPDATE_ITEM_REQUEST = "UPDATE_ITEM_REQUEST";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_FAIL = "UPDATE_ITEM_FAIL";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_REQUEST = "UPLOAD_REQUEST";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";
export const DELETE_ITEM_RESET = "DELETE_ITEM_RESET";

const AllItemReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ITEM_REQUEST:
      return { loading: true };
    case GET_ALL_ITEM_FAIL:
      return { loading: false };
    case GET_ALL_ITEM:
      return { success: true, allItems: action.payload, loading: false };
    default:
      return state;
  }
};

const CreateItemReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ITEM_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        itemInfo: null,
      };
    case CREATE_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        itemInfo: action.payload,
      };
    case CREATE_ITEM_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        itemInfo: null,
      };
    case CREATE_ITEM_RESET:
      return {};
    default:
      return state;
  }
};

const UploadImageReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        path: null,
      };
    case UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        path: action.payload,
      };
    case UPLOAD_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        path: null,
      };

    default:
      return state;
  }
};

const UpdateItemReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ITEM_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        itemInfo: null,
      };
    case UPDATE_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        itemInfo: action.payload,
      };
    case UPDATE_ITEM_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        itemInfo: null,
      };

    default:
      return state;
  }
};

const deleteItemReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ITEM_REQUEST:
      return {
        loading: true,
        success: false,
        error: null,
        userId: null,
      };
    case DELETE_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        userId: action.payload,
      };
    case DELETE_ITEM_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        userId: null,
      };
    case DELETE_ITEM_RESET:
      return {};
    default:
      return state;
  }
};

export {
  deleteItemReducer,
  UploadImageReducer,
  UpdateItemReducer,
  CreateItemReducer,
  AllItemReducer,
};
