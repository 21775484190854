import React, { Component } from "react";
import Style from "./style.module.scss";
import {

  Sector,

} from "recharts";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineFeed } from "react-icons/md";
import { connect } from "react-redux";
import { Admin } from "../../lib/services";
import { FormatCurrencyToVND } from "../../utils/systems";

const { GetAllOrder } = Admin.GetRequests;
const { updateOrder } = Admin.PostRequests;


class Dashboard extends Component {
  demoUrl = "https://codesandbox.io/s/two-simple-pie-chart-otx9h";

  state = {
    activeIndex: 0,
  };
  renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Tổng ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  componentDidMount() {
    this.props.GetAllOrder();
  }
  monthTickFormatter = (tick) => {
    const date = new Date(tick);

    return date.getMonth() + 1;
  };
  renderQuarterTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    if (month % 3 === 1) {
      return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
    }

    const isLast = month === 11;

    if (month % 3 === 0 || isLast) {
      const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

      return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    }
    return null;
  };
  render() {
    const { order = {} } = this.props;
    const totalOrderTotal = Object.values(order).reduce(
      (total, currentOrder) => {
        if (
          currentOrder.shipping_address &&
          currentOrder.shipping_address.order_status !== "Đang chờ xác nhận"
        ) {
          return total + (currentOrder.order_total || 0);
        }
        return total;
      },
      0
    );

    const numberOfOrders = Object.values(order).filter(
      (currentOrder) =>
        currentOrder.shipping_address &&
        currentOrder.shipping_address.order_status !== "Đang chờ xác nhận"
    ).length;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const totalOrderTotalInMonth = Object.values(order).reduce(
      (total, currentOrder) => {
        if (
          currentOrder.shipping_address &&
          currentOrder.shipping_address.order_status !== "Đang chờ xác nhận" &&
          currentOrder.shipping_address.createdAt
        ) {
          const createdAtDate = new Date(
            currentOrder.shipping_address.createdAt
          );
          const createdAtYear = createdAtDate.getFullYear();
          const createdAtMonth = createdAtDate.getMonth();

          // Kiểm tra nếu createdAt nằm trong tháng và năm hiện tại
          if (
            createdAtYear === currentYear &&
            createdAtMonth === currentMonth
          ) {
            return total + (currentOrder.order_total || 0);
          }
        }
        return total;
      },
      0
    );

    const numberOfOrdersMonth = Object.values(order).filter(
      (currentOrder) =>
        currentOrder.shipping_address &&
        currentOrder.shipping_address.order_status !== "Đang chờ xác nhận" &&
        currentOrder.shipping_address.createdAt &&
        new Date(currentOrder.shipping_address.createdAt).getFullYear() ===
          currentYear &&
        new Date(currentOrder.shipping_address.createdAt).getMonth() ===
          currentMonth
    ).length;

    return (
      <div className={`${Style.container} container`}>
        <div className={`${Style.row} row`}>
          <div className={`${Style.revenueContainer} col-sm`}>
            <div className={Style.totalRevenue}>
              <div className={Style.row_title}>
                <h3>Tổng doanh thu(cộng dồn)</h3>
                <span>
                  <GiMoneyStack />{" "}
                </span>
              </div>
              <p className={Style.resultNumber}>
                ${FormatCurrencyToVND(totalOrderTotal)}
              </p>
            </div>
            <div className={Style.currentMonthRevenue}>
              <h3>Tổng doanh thu tháng(tháng hiện tại)</h3>
              <p className={Style.resultNumber}>
                {" "}
                ${FormatCurrencyToVND(totalOrderTotalInMonth)}
              </p>
            </div>
          </div>
          <div className={`${Style.saleFeeSpendContainer} col-sm`}>
            <div className={Style.totalFee}>
              <div className={Style.row_title}>
                <h3>Số lượng Đơn Hàng Đã Xác Nhận</h3>
                <span>
                  <MdOutlineFeed />
                </span>
              </div>
              <p className={Style.resultNumber}>{numberOfOrders} đơn</p>
            </div>
            <div className={Style.currentMonthFee}>
              <h3>Số lượng Đơn Hàng Đã Xác Nhận Trong tháng </h3>
              <p className={Style.resultNumber}>{numberOfOrdersMonth}</p>
            </div>
          </div>
        </div>
        {/* <div className={`${Style.row} row`}>
          <div className={`${Style.revenuePieChart} col-sm`}>
            <h3>Biểu đồ doanh thu</h3>
            <PieChart width={500} height={300}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                activeIndex={this.state.activeIndex}
                outerRadius={80}
                innerRadius={50}
                activeShape={this.renderActiveShape}
                onMouseEnter={this.onPieEnter}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
          <div className={`${Style.revenueDirectSale} col-sm`}>
            <h3>
              Doanh số tháng theo từng kênh bán hàng trực tiếp (triệu VND)
            </h3>
            <BarChart
              width={500}
              height={300}
              data={data_1}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={40}
            >
              <XAxis
                dataKey="name"
                scale="point"
                padding={{ left: 30, right: 10 }}
              />
              <YAxis />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="Thu" fill="#8884d8" background={{ fill: "#eee" }} />
            </BarChart>
          </div>
        </div>
        <div className={`${Style.row} row`}>
          <div className={`${Style.revenueMonthCTVSale} col-sm`}>
            <h3>Doanh số thang theo Dai ly/CTV</h3>
            <BarChart
              width={500}
              height={300}
              data={data_2}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={40}
            >
              <XAxis
                dataKey="name"
                scale="point"
                padding={{ left: 30, right: 10 }}
              />
              <YAxis />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="Thu" fill="#8884d8" background={{ fill: "#eee" }} />
            </BarChart>
          </div>
          <div className={`${Style.growthamonth} col-sm`}>
            <h3>Tăng trưởng theo quý</h3>
            <LineChart width={500} height={300} data={data_3}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Offline"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="Online" stroke="#82ca9d" />
            </LineChart>
          </div>
        </div>
        <div className={`${Style.row} row`}>
          <div className={`${Style.revenueMonthTotal} col-sm`}>
            <h3>Doanh số theo tháng</h3>
            <BarChart
              width={500}
              height={300}
              data={data_4}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={this.monthTickFormatter} />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                interval={0}
                tick={this.renderQuarterTick}
                height={1}
                scale="band"
                xAxisId="quarter"
              />
              <YAxis />
              <Tooltip />
              <Bar dataKey="total" fill="#8884d8" />
            </BarChart>
          </div>
          <div className={`${Style.eventDiscount} col-sm`}>
            <h3>Chương trình khuyến mãi - ưu đãi</h3>
            <ul>
              <li>Chong trinh mua 1 tang 1</li>
              <li>Churong trinh min phi giao hang</li>
              <li>Churong trinh combo</li>
              <li>Uu dai Tét</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    order: state.AllOrderReducer.order,
  };
};

const mapDispatchToProps = {
  updateOrder,
  GetAllOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
